.container {
  margin-top: 32px;
  margin-bottom: 48px;
}

.title {
  color: var(--primary-text-color);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  margin-bottom: 16px;
  display: block;
}

.icon {
  width: 20px;
  height: 20px;
}

.row {
  display: flex;
  gap: 12px;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--link-text-color);
}

.link {
  text-decoration: inherit;
  color: inherit;
}

.chargingStation {
  margin-top: 16px;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-text-color);
}
.chargingStationId {
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: var(--primary-text-color);
}

.logo {
  width: 109px;
  margin-top: 24px;
}

.footerLogo {
  width: 100%;
}
