.container {
  padding-top: 60px;
  padding-right: 20px;
  padding-left: 20px;
}

.headerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: var(--primary-text-color);
  margin-bottom: 24px;
}

.imageContainer {
  align-items: center;
  position: relative;
  height: auto;
  margin-bottom: 24px;
}

.image {
  width: 304px;
}

.statusIcon {
  position: absolute;
  top: -4px;
  right: 75px;
}
