.container {
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
