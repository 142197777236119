@font-face {
  font-family: 'ProximaNova';
  font-weight: 400;
  font-style: normal;
  src:
    local('ProximaNova'),
    url('./assets/fonts/ProximaNova-Light.otf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 500;
  font-style: normal;
  src:
    local('ProximaNova'),
    url('./assets/fonts/ProximaNova-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 600;
  font-style: normal;
  src:
    local('ProximaNova'),
    url('./assets/fonts/ProximaNova-Semibold.otf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 700;
  font-style: normal;
  src:
    local('ProximaNova'),
    url('./assets/fonts/ProximaNova-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: 800;
  font-style: normal;
  src:
    local('ProximaNova'),
    url('./assets/fonts/ProximaNova-Extrabold.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'ProximaNova', serif;
}
