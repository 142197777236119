.container {
  margin: 40px 0;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}

.paragraph {
  font-size: 16px;
  line-height: 24px;
}
