:root {
  --primary-text-color: #0a1f44;
  --secondary-text-color: #8a94a6;
  --primary-background-color: #e8edf3;
  --link-text-color: #0284fe;
  --grey-500: #d0d6df;
  --grey-600: #cecece;
  --grey-900: #8a94a6;
  --success-200: #f5fbf8;
  --success-400: #d6efe6;
  --success-600: #36ab80;
  --success-900: #136a4a;
  --maxem-brand-color: #36c3fe;
  --button-color: #2da5d8;
  --error-600: #f4541d;
}

p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
